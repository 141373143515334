import React from "react";

const HistoryIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 7.00002L6.5 7.00002M16.5 2.00002L6.5 2.00002M16.5 12L6.5 12M3.16667 7.00002C3.16667 7.46026 2.79357 7.83335 2.33333 7.83335C1.8731 7.83335 1.5 7.46026 1.5 7.00002C1.5 6.53978 1.8731 6.16669 2.33333 6.16669C2.79357 6.16669 3.16667 6.53978 3.16667 7.00002ZM3.16667 2.00002C3.16667 2.46026 2.79357 2.83335 2.33333 2.83335C1.8731 2.83335 1.5 2.46026 1.5 2.00002C1.5 1.53978 1.8731 1.16669 2.33333 1.16669C2.79357 1.16669 3.16667 1.53978 3.16667 2.00002ZM3.16667 12C3.16667 12.4603 2.79357 12.8334 2.33333 12.8334C1.8731 12.8334 1.5 12.4603 1.5 12C1.5 11.5398 1.8731 11.1667 2.33333 11.1667C2.79357 11.1667 3.16667 11.5398 3.16667 12Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HistoryIcon;
