import React from "react";

const ICPIcon = () => {
  return (
    <svg
      width="21"
      height="11"
      viewBox="0 0 21 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31_31)">
        <path
          d="M15.8963 0C14.7257 0 13.444 0.666852 12.092 1.9822C11.454 2.60011 10.8921 3.27308 10.4824 3.80534C10.4824 3.80534 11.1379 4.59455 11.8578 5.44494C12.25 4.93103 12.806 4.23359 13.4557 3.60345C14.6555 2.42881 15.4398 2.19021 15.8904 2.19021C17.5761 2.19021 18.9456 3.67075 18.9456 5.5C18.9456 7.3109 17.5761 8.79143 15.8904 8.80979C15.8085 8.80979 15.7149 8.79755 15.5978 8.77308C16.0894 9.01168 16.6221 9.18298 17.1195 9.18298C20.2098 9.18298 20.8185 6.94994 20.8537 6.79088C20.9415 6.38098 20.9941 5.95272 20.9941 5.51224C20.9883 2.47163 18.7057 0 15.8963 0Z"
          fill="url(#paint0_linear_31_31)"
        />
        <path
          d="M5.09783 11C6.26839 11 7.55017 10.3332 8.90217 9.01781C9.54013 8.3999 10.102 7.72693 10.5117 7.19467C10.5117 7.19467 9.85619 6.40546 9.13629 5.55507C8.74415 6.06898 8.18813 6.76642 7.53846 7.39656C6.33863 8.55896 5.54849 8.8098 5.10368 8.8098C3.41806 8.8098 2.0485 7.32927 2.0485 5.50001C2.0485 3.68911 3.41806 2.20858 5.10368 2.19022C5.18562 2.19022 5.27926 2.20246 5.39632 2.22693C4.90468 1.98833 4.37207 1.81703 3.87458 1.81703C0.784281 1.81091 0.175585 4.04395 0.140468 4.20913C0.0526756 4.61903 0 5.04728 0 5.48777C0 8.52838 2.28261 11 5.09783 11Z"
          fill="url(#paint1_linear_31_31)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.45072 3.65239C7.11126 3.30979 5.46076 1.90879 3.88049 1.85984C1.05942 1.78643 0.234168 3.9277 0.158081 4.203C0.702395 1.80478 2.71577 0.0122358 5.10373 0C7.05273 0 9.02514 2.00056 10.4825 3.80534L10.4883 3.79922C10.4883 3.79922 11.1439 4.58843 11.8638 5.43882C11.8638 5.43882 12.6832 6.44828 13.5494 7.33537C13.8889 7.67798 15.5335 9.06062 17.1138 9.10957C20.0109 9.19522 20.8128 6.93159 20.8537 6.76029C20.3211 9.17686 18.3019 10.9755 15.9022 10.9878C13.9532 10.9878 11.9808 8.98721 10.5176 7.18243C10.5176 7.18854 10.5118 7.18854 10.5118 7.19466C10.5118 7.19466 9.85624 6.40545 9.13634 5.55506C9.1422 5.55506 8.3228 4.53949 7.45072 3.65239Z"
          fill="#29ABE2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_31_31"
          x1="13.1563"
          y1="0.871582"
          x2="20.6939"
          y2="8.14191"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stop-color="#F15A24" />
          <stop offset="0.6841" stop-color="#FBB03B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_31_31"
          x1="7.83966"
          y1="10.1297"
          x2="0.302106"
          y2="2.85935"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.21" stop-color="#ED1E79" />
          <stop offset="0.8929" stop-color="#522785" />
        </linearGradient>
        <clipPath id="clip0_31_31">
          <rect width="21" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ICPIcon;
