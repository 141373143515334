import React from "react";

const SwapIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00008 5.00002L6.66675 3.33335M6.66675 3.33335L5.00008 1.66669M6.66675 3.33335H5.00008C3.15913 3.33335 1.66675 4.82574 1.66675 6.66669M15.0001 15L13.3334 16.6667M13.3334 16.6667L15.0001 18.3334M13.3334 16.6667H15.0001C16.841 16.6667 18.3334 15.1743 18.3334 13.3334M11.1811 11.181C11.8329 11.4924 12.5628 11.6667 13.3334 11.6667C16.0948 11.6667 18.3334 9.42811 18.3334 6.66669C18.3334 3.90526 16.0948 1.66669 13.3334 1.66669C10.572 1.66669 8.33342 3.90526 8.33342 6.66669C8.33342 7.43728 8.50774 8.16716 8.81911 8.81905M11.6667 13.3334C11.6667 16.0948 9.42817 18.3334 6.66675 18.3334C3.90532 18.3334 1.66675 16.0948 1.66675 13.3334C1.66675 10.5719 3.90532 8.33335 6.66675 8.33335C9.42817 8.33335 11.6667 10.5719 11.6667 13.3334Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SwapIcon;
